<template lang="pug">
    div
        div(class="p-4 text-center box-bg rounded")
            // img(src="@/assets/icons/icon-enterprise-white.png" alt="Enterprise" class="m-auto max-w-1/4 my-6")
            h4 Enterprise
            div.mt-1.mb-2.text-xs.parent-tooltip
                |  Organisations
                v-tooltip(bottom attach=".parent-tooltip")
                    template(v-slot:activator="{ on, attrs }")
                        span(v-bind="attrs" v-on="on" class="inline-block relative")
                            fa-icon(
                                icon="question-circle"
                                class="text-white ml-1")
                    span such as Primary Health Networks, day clinics, hospitals, allied health, specialists.
            div.flex.items-center.justify-center.mt-4.text-white
                div.text-2l.text-white.font-semibold Contact us
            div.text-xs.uppercase.mb-4 for quote
            //below button needs to link to website contact form
            v-btn(
                href="https://www.ultimateq.health/contact"
                target="_blank"
                class="text-white w-full my-2"
                depressed
                color="primary"
                raised
                large
            )
                | Get Quote
            v-expansion-panels(flat class="planFeatures")
                  v-expansion-panel
                    v-expansion-panel-header
                        div.text-white.font-display.text-sm Features
                    v-expansion-panel-content
                        div.text-left.text-white.text-xs
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div All the features of UltimateQ™ Premium plus
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Fully customisable, brandable, tailored, and bespoke solutions
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Compare results across the group
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Custom built surveys
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Forms builder using QR codes for new patient registrations
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Create your own message and personalised feedback
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Individual and dedicated client manager / client support representative
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Patient focus group
</template>

<script>
export default {
    name: "EnterpriseOption",
};
</script>
