<template lang="pug">
    div(:class="{'selectedPlan': isSelected}")
        div(v-if="isSelected" class="selectedMsg absolute flex items-center justify-center bg-primary rounded")
            fa-icon(icon="check" class="text-white text-xs")
            div.text-xs.uppercase.font-semibold.ml-1 Selected
        div(class="p-4 text-center box-bg rounded")
            // img(src="@/assets/icons/icon-premium-white.png" alt="Premium" class="m-auto max-w-1/4 my-6")
            h4 UltimateQ™
            div.mt-1.mb-2.text-xs Continuous Improvement Software Tool
            div.flex.items-center.justify-center.mt-4.text-white
                div.text-xl.text-white.font-semibold
                    | ${{price}}
                div.text-xs.ml-2 per month
            div.text-xs.uppercase.mb-4 {{ recurrenceText }}
            v-btn(
                class="w-full my-2"
                :class="{'text-white': !isSelected}"
                depressed
                :disabled="isSelected"
                :color="btnColor"
                raised
                large
                @click="setSubscription"
            )
                | {{ btnText }}
            v-expansion-panels(flat class="planFeatures")
                v-expansion-panel
                    v-expansion-panel-header
                        div.text-white.font-display.text-sm Features
                    v-expansion-panel-content
                        div.text-left.text-white.text-xs
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Dedicated links for your practice survey/s
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Patient Satisfaction Score
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Comes preloaded with long &amp; short accreditation surveys (upon request)
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Printable version
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Unlimited surveys
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Comparative data with other practices
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div QR Code posters for collecting survey responses
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Use your practice SMS provider to send your survey to patients
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Realtime CSV extraction for practice analysis
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Tools to assist with analysis and reporting
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Access to other resources to meet practice patient feedback requirements
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Gain control of your feedback collection and review
                            div.flex.items-start.justify-items-start.my-2
                                fa-icon(icon="check" class="text-white mr-2")
                                div Focus your effort on driving your practice’s quality agenda


</template>
<script>
import { SubscriptionType } from '@/common/enums/subscription.type'
import { computed } from '@vue/composition-api'
import { getRecurrenceText } from '@/components/subscription/partials/subscription.helpers'

export default {
    name: 'PremiumOption',
    props: {
        subscription: {
            type: Object,
            required: true
        },
        option: {
            type: String,
            default: null,
        },
        price: {
            type: Number,
            default: null
        }
    },
    emit: ['set-subscription', 'set-subscription-option'],
    setup(props, { emit }) {
        const isSelected = computed(
            () => props.option === SubscriptionType.PremiumPlan
        )
        const subscription = computed(() => props.subscription)
        const recurrenceText = computed(() => getRecurrenceText(props.subscription || {}))

        const setSubscription = () => {
            emit('set-subscription', {
                price: subscription.value.price?.formatted_price,
                priceId: subscription.value?.price?.id,
                option: SubscriptionType.PremiumPlan,
            })
            emit('set-subscription-option', { ...subscription.value })
        }

        const btnText = computed(() =>
            isSelected.value ? 'Selected' : 'Select Premium'
        )
        const btnColor = computed(() => (isSelected.value ? '' : 'primary'))

        return { isSelected, btnText, btnColor, recurrenceText, setSubscription }
    },
}
</script>
